button {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(7,51,116,1) 4%, rgba(5,40,91,1) 49%, rgba(0,0,0,1) 100%);
    font-family: 'Audiowide';
    color: #a3a1a1;
    font-size: 2vw; 
    position: relative;
    top: 0;
    height: 4vw;
    box-shadow:  0px 0.5vw 0px #353535;
    border: 1px solid black;
    border-radius: 2vw;
    margin: 2vw;
    cursor: pointer;
    &:active {
        top: 0.5vw;
        box-shadow:  0px 0px 0px black;
    }
    &[type='button'] {
        color: white;
    }
}

.active {
    top: 0.5vw !important;
    box-shadow:  0px 0px 0px black!important;
    text-shadow: 0 0 5px white;
    color: white !important;
}