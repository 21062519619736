#projet {
    width: 90vw;
    min-height: 35vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1vh;
    #cover_img {
        width: 50vw;
        max-height: 20vw;
        box-shadow: 10px 10px 15px grey;
    }
}

#info_projet {
    display: flex; 
    justify-content: space-around; 
    align-items: center;
    width: 90%;
    margin-top: 2vw;
    a {
        display: flex;
        align-items: center;

    }
}

#projet_description {
    width: 100vw;
    margin-bottom: 5vh;
    h3 {
        font-size: 3vw;
    }
    p {
        font-size: 1.5vw;
    }
    li {
        font-size: 1.5vw;
    }
}
.logo {
    height: 10vw !important;
    filter: drop-shadow(0.2vw 0.2vw 0.2vw grey);
}

.logo_pro{
    a:hover {
        transform: scaleX(1.5);
    }
    width: 7vw;
    filter: drop-shadow(0.2vw 0.2vw 1vw lightgrey);
    margin: 2vw;
    cursor: pointer;
}

#img_projet{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
}
.chevron, .chevron_prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
    height: 50px;
    width: 50px;
    transform: rotate(-90deg);
    filter: drop-shadow(0px 2px 6px white);
    position: relative;

  }
  .chevron_prev {
    transform: rotate(90deg);
  }

@media all and (max-width: 768px) {
    #img_projet{
        width: 100vw;
        flex-direction: column;
        gap: 7vw;
        #cover_img {
            width: 90vw;
            max-height: 40vw;
        }
        #info_projet{
           width: 90vw;
        }
    }
    #projet_description {
        p {
            font-size: 5vw;
        }
        h3{
            font-size: 10vw;
        }
        li {font-size: 5vw;
        }
    }
    #projet {
        #cover_img {
            box-shadow: 1vw 1vw 1vw grey;
        }
    }
}