@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

@font-face {
    font-family: AudioWide;
    src: url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
  }

#technologie {
    display: flex;
    flex-direction: column;
    height: 85vh;
    margin-top: 5vw;
    color: white;
    img {
        width: 10vw;
        position: relative;
        filter: drop-shadow(2px 1px 0px rgb(91, 91, 91));  
    }
}

#logo_techno{
    position: absolute;
    opacity: 0.6;
}

#logo_HTML {
    top: -6vw;
    left: 5vw;
}
#logo_CSS{
    top: 20vh;
    left: 15vw;
}
#logo_JavaScript{
    width: 8vw !important;
    top: 50vh;
    right: 10vw;
}
#logo_React{
    top: 10vh;
    left: 55vw;
}
#logo_Sass{
top: 40vh;
left: 40vw;
}
#logo_GitHub{
    top: 55vh;
    right: 10vw;
}
#logo_MongoDB{
    top: 15vh;
    right: 15vw;
}
#logo_Node{
    top: 50vh;
    right: 10vw;    
}

#table {
    display: flex;
    justify-content: space-around;
    h3 {
        text-align: center;
        color: #ffc72c;
        text-shadow: 0px 0px 20px;
    }
    ul {
        list-style: none;
        font-size: 2vw;
        font-weight: 500;
        padding: 0;
        text-align: center;
    }
    #outils{
        column-count: 2;
    }
}

@media all and (max-width: 768px) {
    #table {
        flex-direction: column;
        h3 {
            font-size: 5vw;
        }
        ul {
            font-size: 6vw;
        }
    }
}
