#login  {
    padding: 3vw;
    & h2{
        text-align: center;
    }
    #form_signUp, #form_login{
        height: auto;
        flex-direction: column;
        margin: 10vw auto;
        align-items: center;
        justify-content: flex-start;
    }
}

#nav_login{
    position: relative;
    display: flex;
    justify-content: center;
        button {
            width: auto;
            padding: 1vw 3vw;
            height: auto;
            font-size: 2vw;
            box-shadow:  0px 0.4vw 0px #353535;
            &:active {
                top: 0.4vw;
                box-shadow:  0px 0px 0px #353535;
            }
        }
}

@media all and (max-width: 1024px) {
    #login {
        height: 100vh;
    }
    #nav_login button {
        padding: 2vw!important;
    }
}


