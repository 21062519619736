header {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    position: fixed;
    top: 0;
    box-shadow: 0px 5px 20px 0px grey;
    z-index: 3;
    background: radial-gradient(circle, rgba(3,30,54,1) 0%, rgba(0,0,0,1) 100%);
    z-index: 3;
    height: 6vw;
    transition: height 600ms;
    a {
        display: flex;
        margin: 1.5vw;
    }
    #logo1 {
        width: 7vw;
    }
    #logo2 {
        width: 20vw;
    }
    #accueil{
        width: 95vw;
        justify-content: space-between;
    }
}

#nav_header {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 1.5vw;
    margin-right: 2vw;
    a {
        color: #a3a1a1;
        font-family: 'AudioWide';
        text-decoration: none;
        &:hover {
            transform: scale(1.2);
            transition: transform 600ms;
        }
    }
    .active {
        text-shadow: 0 0 20px white ;
    }
}

@media all and (max-width: 768px) {
    header{
        height: 20vw;
        #logo2  {
            display: none;
        }
    }
    #logo1 {
        width: 20vw!important;
    }
    
    #nav_header {
        font-size: 4vw;
    }

}
