
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

button {
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(7, 51, 116) 4%, rgb(5, 40, 91) 49%, rgb(0, 0, 0) 100%);
  font-family: "Audiowide";
  color: #a3a1a1;
  font-size: 2vw;
  position: relative;
  top: 0;
  height: 4vw;
  box-shadow: 0px 0.5vw 0px #353535;
  border: 1px solid black;
  border-radius: 2vw;
  margin: 2vw;
  cursor: pointer;
}
button:active {
  top: 0.5vw;
  box-shadow: 0px 0px 0px black;
}
button[type=button] {
  color: white;
}



.active {
  top: 0.5vw !important;
  box-shadow: 0px 0px 0px black !important;
  text-shadow: 0 0 5px white;
  color: white !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main {
  background-image: url("../src/assets/images/17973903.webp");
  background-size: cover;
  position: relative;
  top: 6vw;
  color: white;
}

h2 {
  font-family: AudioWide;
  font-weight: 800;
  font-size: 4vw;
}

h3 {
  font-family: AudioWide !important;
  font-size: 3vw;
  font-weight: 800;
}

@media all and (max-width: 768px) {
  main {
    top: 20vw !important;
  }
  p {
    font-size: 5vw !important;
  }
}

