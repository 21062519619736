#description {
    width: 90vw;
    min-height: 59vh;
    margin: auto;
    padding: 2vw 0;
    display: flex;
    justify-content: space-around;
    p {
        width: 50vw;
        font-size: 1.5vw;
    }
}

#portrait {
    width: 24vw;
    height: 32vw;
    background: url('../../assets/images/profil.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 145px;
    box-shadow: 5px 5px 10px 0px #353535;
}
#presentation img{
    display: inline;
    height: 3vw;
    margin: 0 1vw;
}

@media all and (max-width: 768px) {
    #description {
        flex-direction: column;
        align-items: center;
        justify-content: normal;
        #portrait {
            width: 60vw;
            height: 70vw;
            box-shadow: 0 0 0 0 ;
            border-radius: 0;
            background-size: contain;
        }
        p {
            padding: 0;
            width: 90vw;
            font-size: 5vw;
            text-align: center;
        }
    }
    #presentation div {
        display: flex;
        justify-content: space-evenly;
        img{
        height: 10vw;
        text-align: center;
        margin: 0 1vw;
    }
}
}


