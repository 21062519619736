#projets {
    display: flex;
    width: 90vw;
    min-height: 85vh;
    margin: auto;
    flex-direction: column;
    
    a {
        text-decoration: none;
        color: #ffc72c;
    }
}

#formation{
    & button {
        width: 95%;
        height: 30vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid grey;
        border-radius: 20px;
        box-shadow: 5px 5px 5px rgb(78, 2, 70);
        cursor: pointer;
        font-size: 6vw;
        color: white;
    }
}

#openclassrooms {
    background: url('../../assets/images/Openclassrooms.png');
}

#sideProjet {
    opacity: 0.7;
}

.cardSideProjet{
    width: 20vw;
    min-height: 25vw;
    border-radius: 3vw;
    background-size: contain;
    align-content: center;
    margin-bottom: 3vw;
    h3 {
        color: black;
        margin: 1vw 2vw;
        font-size: 1vw;
        writing-mode: vertical-rl;
        text-orientation: upright;
        white-space: nowrap;
        letter-spacing: -0.2vw;
    }
}

.container{
    display: none;
    justify-content: center!important;

    width: 90vw;
    justify-content: space-between;
}

#preview_projet {
    width: 45vw;
    #img_preview {
        width: 45vw;
        height: 40vh;
        box-shadow: 5px 5px 15px grey;
        border-radius: 20px;
    }
}

#titre_projet {
    width: 45vw;
    height: 86vh;
    position: relative;
    top: -29vh;
    overflow: hidden;
}

#name_projet {
    li {
        list-style: none;
        cursor: pointer;
        a:hover{
            color: white !important;
            text-shadow: 0 0 20px #fff;
        }
        a {
            text-decoration: none;
            font-size: 3vw;
            color: #a3a1a1;
        }
    }
}

.open_section{
    display: flex;
}
.close_section{
    display: none!important;
}

#name_projet {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1vh;
}

#Btn_projets {
    border: none;
    background: none;
    text-align: left;
    width: 15vw;
    cursor: pointer;
}

.mobile {
    #img_preview {
        display: none;
    }
    #preview_projet {
        display: none;
    }
    #titre_projet {
        width: 90%;
        margin: auto;
        height: auto;
        top: -2vw; 
        #name_projet {
            padding: 0;
            gap: 3vw;
        } 
        ul{ 
        a {
            width: 90%;
            margin: auto;
        }
        li{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 1vw;
            border: 2px solid black;
            padding: 14vw 0;
            box-shadow: 0.2vw 0.2vw 1vw #909090;
        }
    }
        
        #name_projet {
            height: auto;
        }
    }
    #container {
        height: auto;
    }
}

@media all and (max-width: 768px) {
    #projets {
        h2 {
            font-size: 8vw;
        }
    }
    #formation button {
        height: 20vw!important;
    }
    .cardSideProjet {
        height: 35vw;
        width: 25vw;
    }
    
}