#contact{
    text-align: center;
    height: auto;
    padding: 3vw;
    p {font-size: 2vw;}
}

form {
    display: flex;
    flex-direction: row;
    #form1{
        margin: 4vh auto;
        width: 40vw;
        display: flex;
        flex-direction: column;
    }
    #form2{
        margin: 6vh auto;
        width: 40vw;
    }
    label{
        text-align: left;
        font-weight: 600;
        font-size: 2vw;
        margin: 1vw;
    }
    input {
        color: white;
        padding: 1vw;
        font-size: 2vw;
        background-color: rgba(10, 9, 9, 0.134);
        box-shadow: 2px 2px 2px grey;
        border: none;
        border-radius: 10px;
    }
    #message {
        width: 40vw;
        height: 17vw;
        margin: 1vw;
        border: none;
        border-radius: 10px;
        background-color: rgba(10, 9, 9, 0.134);
        box-shadow: 2px 2px 2px grey;
    }
        textarea {
            padding: 1vw;
            font-size: 2vw;
            color: white;
        }
        button {
            width: 30vw;
        }
    
}

@media all and (max-width: 1024px) {
    #contact {       
        p {
        font-size: 3vw;
        }
        h3 {
            font-size: 5vw;
        }
    }
    form {
        flex-direction: column;
        label, input {
            font-size: 4vw;
        }
        #form1, #form2 {
            width: 80vw;
              #message {width: 80vw;}
        }
    }
}

