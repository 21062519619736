#avis {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#formAvis {
    flex-direction: column;
}

#stars_Form{
    .Stars {
        top: 0!important;
        direction: rtl;
         .fa-solid:hover,
         .fa-solid:focus {
            color: #ffc72c;
            cursor: pointer;
         }
         .fa-solid:hover ~ .fa-solid {
            color: #ffc72c;
        }
    }  
}

#displayAvis {
    width: 90vw;
    margin: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#avis_login {
    font-family: AudioWide;
    font-size: 1.5vw;
    text-align: center;
    button {
        width: 30vw;
    }
    a {
        color: #a3a1a1;
        text-decoration: none;
    }
}

@media all and (max-width: 768px) {
    #displayAvis {
        flex-direction: column;
        .comment {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
            height: auto;
            padding: 5vw 0;
            margin: 2vw;
        }
    }
    .Stars{
        font-size: 4vw;
    }
    #avis {
        .Stars {
            margin: 0;
        }
    }
}