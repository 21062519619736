.Stars {
    width: 21vw;
    display: flex;
    justify-content: center;
    font-size: 2vw;
    color: #E3E3E3
}

.star-rating {
    color: #ffc72c;
  }