#avis {
    .Stars {
    position: relative;
    direction: rtl;
    margin: 2vw;
    }
}

.comment {
    color: white;
    background: radial-gradient(circle, rgba(3,30,54,1) 0%, rgba(0,0,0,1) 100%);
    border-radius: 1vw;
    padding: 0 1vw;
    margin: 0 1vw;
    box-shadow: 2px 2px 2px grey;
}
