@import './components/button.scss';
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Playfair+Display&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
.main{
    background-image: url('../src/assets/images/17973903.webp');
    background-size: cover;
    position: relative;
    top: 6vw;
    color: white;
}

h2 {
    font-family: AudioWide;
    font-weight: 800;
    font-size: 4vw;
}

h3 {
    font-family: AudioWide!important;
    font-size: 3vw;
    font-weight: 800;
}

@media all and (max-width: 768px) {
  main {
    top: 20vw!important;
  }
  p {
    font-size: 5vw!important;
  }
}